.BuildYourFutureWorkspace-div {
  min-height: 100vh;
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  position: relative;
  background-color: #8f2b8f;
}

.BuildYourFutureWorkspace-content {
  z-index: 2000 !important;
  margin-top: calc(30px + 6.631vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fdfdfd;
}
.BuildYourFutureWorkspace-button {
  z-index: 3000 !important;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 5px solid transparent;
  width: fit-content;
  color: #fdfdfd;
  /* border: 1px solid #3a3632; */
  border: 1px solid #fdfdfd;
  background-color: transparent !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; 
  padding: 12px 50px;
  letter-spacing: 1.5px; 
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  background: linear-gradient(to left, transparent 50%, #3a3632 50%) right !important;
  background-size: 200% 100% !important;
  margin-top: 50px;
}


.BuildYourFutureWorkspace-button:hover {
  border: 1px solid #3a3632;
  background-position: left !important;
}

.BuildYourFutureWorkspace-img {
  position: absolute;
  right: 6.631vw;
  bottom: 2vh;
  z-index: 0 !important;
}
.BuildYourFutureWorkspace-img img {
  height: 80vh;
  z-index: 0 !important;
}
.BuildYourFutureWorkspace-img-mobile {
  position: static;
  z-index: 0 !important;
  display: none;
  width: 100%;
}
.BuildYourFutureWorkspace-img-mobile img {
  width : 100%;
  height: auto;
}
@media screen and (max-width: 767px) {
  .BuildYourFutureWorkspace-div-button {
    margin-top: 300px;
    padding: 12px 50px;
    background: linear-gradient(to left, transparent 50%, #3a3632 50%) right !important;
    background-size: 200% 100% !important;
    }
    
    .BuildYourFutureWorkspace-button:hover {
      border: 1px solid #3a3632;
      background-position: left !important;
    }
}



.BuildYourFutureWorkspace-inside-div .h4-subheading{
  margin: 0 !important;
  padding: 0 !important;
}
.BuildYourFutureWorkspace-inside-div .h2-heading{
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 10px !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .BuildYourFutureWorkspace-div{
    padding: 3.315vh 6.510vw !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 3.315vh);
  }
  .BuildYourFutureWorkspace-img{
    display: none;
  }
  .BuildYourFutureWorkspace-img-mobile{
    display: block;
    position: static;
    margin-top: 10px;
  }
  .BuildYourFutureWorkspace-img-mobile img{
    width : 100%;
    height: auto;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape) {
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 3.315vw);
  }
  .BuildYourFutureWorkspace-div{
    padding: 3.315vw 6.510vh !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
    min-height: fit-content;
  }
  .BuildYourFutureWorkspace-img{
    display: none;
  }
  .BuildYourFutureWorkspace-img-mobile{
    display: block;
    position: static;
    margin-top: 10px;
  }
  .BuildYourFutureWorkspace-img-mobile img{
    width : 100%;
    height: auto;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .BuildYourFutureWorkspace-div{
    min-height: 100vh;
    padding: 3.978vh 8.138vw !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;;
  }
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 3.978vh);
  }
  .BuildYourFutureWorkspace-img{
    display: none;
  }
  .BuildYourFutureWorkspace-img-mobile{
    display: block;
    position: static;
    margin-top: 50px;
  }
  .BuildYourFutureWorkspace-img-mobile img{
    width : 100%;
    height: auto;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .BuildYourFutureWorkspace-div{
    min-height: 100vh;
    max-height: 100vw;
    padding: 3.978vw 8.138vh !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 3.978vw);
  }
  .BuildYourFutureWorkspace-img{
    display: block;
    position: static;
    width: 100%;
  }
  .BuildYourFutureWorkspace-img img{
    width: 100%;
    height: auto;
    transform: translateY(-80px);
  }
  .BuildYourFutureWorkspace-img-mobile{
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  BuildYourFutureWorkspace-div{
    min-height: 100vh;
    max-height: max-content;
    padding: 4.641vh 9.765vw !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
  }
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 4.641vh);
  }
  .BuildYourFutureWorkspace-img{
    display: none;
  }
  .BuildYourFutureWorkspace-img-mobile{
    display: block;
    position: static;
    height: fit-content;
  }
  .BuildYourFutureWorkspace-img-mobile img{
    width : 100%;
    height: auto;
    transform: translateY(0);
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape){
  .BuildYourFutureWorkspace-div{
    min-height: 100vh;
    max-height: 100vw;
    padding: 4.641vw 9.765vh !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
  }
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 4.641vw);
  }
  .BuildYourFutureWorkspace-img{
    display: block;
    position: static;
    width: 100%;
  }
  .BuildYourFutureWorkspace-img img{
    width: 100%;
    height: auto;
    transform: translateY(-80px);
  }
  .BuildYourFutureWorkspace-img-mobile{
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .BuildYourFutureWorkspace-img-mobile{
    display: none;
  }
  .BuildYourFutureWorkspace-div{
    display: block;
    min-height: 100vh;
    max-height: max-content;
    padding: 5.305vh 11.393vw !important;
    position: relative;
  }
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 5.305vh);
  }
  .BuildYourFutureWorkspace-img {
    display: block;
    width: fit-content;
    position: absolute;
    right: 5.305vw;
    bottom: 2vh;
    margin-top: 0px;
  }
  .BuildYourFutureWorkspace-img img {
    transform: translateY(0);
    height: 70vh;
    width: auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .BuildYourFutureWorkspace-img-mobile{
    display: none;
  }
  .BuildYourFutureWorkspace-div{
    min-height:100vh;
    padding: 6.631vh 13.02vw !important;
    
  }
  .BuildYourFutureWorkspace-content {
    margin-top: calc(30px + 6.631vh);
  }
  .BuildYourFutureWorkspace-img {
    right: 6.631vw;
    bottom: 0vh;
  }
  .BuildYourFutureWorkspace-img img {
    height: 78vh;
    width: auto;
  }
}


.homepage-heading {
  font-size: 50px;
  font-weight: 500;
  color: #fdfdfd;
}
@media only screen and (max-width: 767px) {
    .homepage-heading {
      font-size: 30px;
      line-height: 1.1;
    }
}