.scroll-to-top{
    position: fixed !important;
    height: 40px !important;
    width: 40px !important;
    bottom: 20px !important;
    right: 20px !important;
    display: none !important;
    z-index: 4000 !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
    box-shadow: 0 0 5px #8f2b8f !important;
    color: #2C7FBF !important;
    box-sizing: border-box !important;
    background-color: #fdfdfd !important;
}
.scroll-to-top:hover, .scroll-to-top:focus {
    box-shadow: 0 0 20px #8f2b8f !important;
    border-color: #8f2b8f !important;
}
.scroll-to-top-hidden{
    display: none !important;
}
.scroll-to-top-icon{
    color:  #8f2b8f !important;
}