.why-lejhro-content {
  /* height: 100vh; */
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: #fdfdfd;
  box-sizing: border-box !important;
}
.why-lejhro-content-left {
  width: 40%;
}
.why-lejhro-content-right {
  width: 50%;
}
.bullet-point {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bullet-point img {
  height: 20px;
  width: 20px;
}
#why-lejhro-content-left {
  order: 1;
}
#why-lejhro-content-right {
  order: 2;
}

.why-lejhro-content-right img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 600px) {
  .why-lejhro-content {
    padding: 3.315vh 6.51vw !important;
    flex-direction: column;
    gap: 20px;
  }
  .why-lejhro-content-right {
    width: 90%;
    margin-bottom: 20px;
  }
  .why-lejhro-content-left {
    width: 100%;
  }
  #why-lejhro-content-left {
    order: 2 !important;
  }
  #why-lejhro-content-right {
    order: 1 !important;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .why-lejhro-content {
    padding: 3.315vw 6.51vh !important;
    flex-direction: column;
    gap: 20px;
  }
  .why-lejhro-content-right {
    width: 90%;
    margin-bottom: 20px;
  }
  .why-lejhro-content-left {
    width: 100%;
  }
  #why-lejhro-content-left {
    order: 2 !important;
  }
  #why-lejhro-content-right {
    order: 1 !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .why-lejhro-content {
    padding: 3.978vh 8.138vw !important;
    flex-direction: column;
    gap: 20px;
  }
  .why-lejhro-content-right {
    width: 90%;
    margin-bottom: 40px;
  }
  .why-lejhro-content-left {
    width: 100%;
  }
  #why-lejhro-content-left {
    order: 2 !important;
  }
  #why-lejhro-content-right {
    order: 1 !important;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .why-lejhro-content {
    padding: 3.978vw 8.138vh !important;
    flex-direction: row !important;
  }
  .why-lejhro-content-right {
    width: 40%;
    margin-bottom: 0px;
  }
  .why-lejhro-content-left {
    width: 60%;
  }
  #why-lejhro-content-left {
    order: 1 !important;
  }
  #why-lejhro-content-right {
    order: 2 !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .why-lejhro-content {
    padding: 4.641vh 9.765vw !important;
    flex-direction: column;
    gap: 20px;
  }
  .why-lejhro-content-right {
    width: 90%;
    margin-bottom: 40px;
  }
  .why-lejhro-content-left {
    width: 100%;
  }
  #why-lejhro-content-left {
    order: 2 !important;
  }
  #why-lejhro-content-right {
    order: 1 !important;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .why-lejhro-content {
    padding: 4.641vw 9.765vh !important;
    flex-direction: row !important;
  }
  .why-lejhro-content-right {
    width: 40%;
    margin-bottom: 0px;
  }
  .why-lejhro-content-left {
    width: 60%;
  }
  #why-lejhro-content-left {
    order: 1 !important;
  }
  #why-lejhro-content-right {
    order: 2 !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .why-lejhro-content {
    padding: 5.305vh 11.393vw !important;
    flex-direction: row !important;
  }
  .why-lejhro-content-right {
    width: 50%;
    margin-bottom: 0px;
  }
  .why-lejhro-content-left {
    width: 40%;
  }
  #why-lejhro-content-left {
    order: 1 !important;
  }
  #why-lejhro-content-right {
    order: 2 !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .why-lejhro-content {
    padding: 6.631vh 13.02vw !important;
    /* height: 100vh !important; */
    flex-direction: row !important;
  }
  .why-lejhro-content-right {
    width: 50%;
  }
  .why-lejhro-content-left {
    width: 40%;
  }
  #why-lejhro-content-left {
    order: 1 !important;
  }
  #why-lejhro-content-right {
    order: 2 !important;
  }
}

.h2-1 {
  display: none;
}
@media only screen and (max-width: 600px) {
  .h2-1 {
    display: block;
  }
  .h2-2 {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .h2-1 {
    display: block;
  }
  .h2-2 {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .h2-1 {
    display: none;
  }
  .h2-2 {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .h2-1 {
    display: block;
  }
  .h2-2 {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .h2-1 {
    display: none;
  }
  .h2-2 {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  .h2-1 {
    display: none;
  }
  .h2-2 {
    display: block;
  }
}
