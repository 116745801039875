.top-header-header-div {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 0 !important;
}
.top-header-header-logo-div {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.top-header-fixed-logo {
  height: 30px;
}
.top-header-links-div {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
}
.top-header-links-div a {
  color: #fdfdfd;
  font-size: 16px;
  width: max-content;
  line-height: 0;
  font-weight: 500;
  z-index: 2000;
}
.top-header-links-div a:hover {
  opacity: 80%;
}
/* .top-header-links-div a:focus {
  opacity: 80%;
} */
.top-header-drawer-button,
.top-header-drawer-button-links {
  display: flex !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  background-color: #3a3632 !important;
  color: #fdfdfd !important;
  margin: 0 !important;
  padding: 0 !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: flex-end !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  background-color: #fdfdfd !important;
}

.top-header-drawer-button {
  display: none !important;
}
.top-header-drawer-button-links {
  margin-left: 100px !important;
}
.top-header-drawer-button div,
.top-header-drawer-button-links div {
  background: #3a3632;
  height: 2px;
  width: 100%;
  margin: 3px 0;
}
.top-header-drawer-button .side-drawer-button-container-div-line2,
.top-header-drawer-button-links .side-drawer-button-container-div-line2 {
  width: 60%;
}
.top-header-drawer-button:hover,
.top-header-drawer-button:focus,
.top-header-drawer-button-links:hover,
.top-header-drawer-button-links:focus {
  background-color: #fdfdfd !important;
  color: #fdfdfd !important;
  border : 1px solid #fdfdfd !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .top-header-fixed-logo {
    height: 20px;
  }
  .top-header-links-div {
    display: none !important;
  }
  .top-header-drawer-button {
    display: flex !important;
  }
}

@media only screen and (max-width: 600px) and (orientation: landscape) {
  .top-header-links-div {
    display: none !important;
  }
  .top-header-drawer-button {
    display: flex !important;
  }
  .top-header-fixed-logo {
    height: 25px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .top-header-links-div {
    display: none !important;
  }
  .top-header-drawer-button {
    display: flex !important;
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  .top-header-links-div {
    display: none !important;
  }
  .top-header-drawer-button {
    display: flex !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .top-header-links-div {
    display: none !important;
  }
  .top-header-drawer-button {
    display: flex !important;
  }
}
@media only screen and (min-width: 992px) and (orientation: landscape) {
  .top-header-links-div {
    display: flex !important;
  }
  .top-header-drawer-button {
    display: none !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .top-header-links-div {
    display: flex !important;
  }
  .top-header-drawer-button-links {
    margin-left: 50px !important;
  }
  .top-header-drawer-button {
    display: none !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .top-header-links-div {
    display: flex !important;
  }
  .top-header-drawer-button-links {
    margin-left: 100px !important;
  }
  .top-header-drawer-button {
    display: none !important;
  }
}
