.side-drawer {
  z-index: 4050 !important;
  background-color: transparent !important;
}
.side-drawer-logo {
  height: 30px;
}
.side-drawer-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* margin-top: 25px; */
  background-color: #fdfdfd;
}
.side-drawer-button-link {
  text-align: left !important;
  border: none !important;
  outline: none !important;
  font-weight: 600;
  font-size: 18px;
  padding: 10px;
  height: fit-content !important;
  box-shadow: none !important;
  color: #3a3632;
}
.side-drawer-button-link:hover,
.side-drawer-button-link:focus {
  color: #3a3632;
}
.side-drawer-close-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  background-color: #fdfdfd !important;
  color: #3a3632 !important;
  border: 2px solid #3a3632 !important;
}
.side-drawer-close-button:hover,
.side-drawer-close-button:focus {
  background-color: #3a3632 !important;
  color: #fdfdfd !important;
}
.size-drawer-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fdfdfd;
}
.side-drawer-connect {
  margin-top: 10px;
  display: flex;
  width: 200px;
  justify-content: center;
  gap: 20px;
}
.side-drawer a {
  color: #3a3632;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.side-drawer a:hover {
  color: #8f2b8f;
}
.ant-drawer-content-wrapper {
  width: 100vw !important;
  max-width: 380px !important;
}
.ant-drawer-body{
  background-color: #fdfdfd;
}
@media only screen and (max-width: 377px) {
  .side-drawer-logo {
    height: 20px;
  }
  .side-drawer-buttons-container {
    padding-left: 20px !important;
  }
  .side-drawer-close-button {
    border-radius: 100% !important;
    height: 40px !important;
    width: 40px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100vw !important;
    width: 100vw !important;
    max-width: 425px !important;
  }
}

@media only screen and (max-width: 600px) {
  .side-drawer-logo {
    height: 20px;
  }
  .side-drawer-buttons-container {
    /* margin-top: 20px !important; */
    padding-left: 20px !important;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .side-drawer-logo {
    height: 25px !important;
  }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .side-drawer-logo {
    height: 25px !important;
  }
  .side-drawer-buttons-container {
    /* margin-top: 20px !important; */
    padding-left: 20px !important;
  }
}
.ant-drawer-body {
  padding-top: 8px !important;
}

.lejhro-logo-primary {
  max-height: 30px!important;
  max-width: 40vw!important;
}
.ant-drawer-title {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  max-width: calc(100% - 48px) !important;
}
.ant-drawer-close {
  position: absolute !important;
  right: 12px !important;
  background-color: #fdfdfd !important;
  border: 2px solid #3a3632 !important;
  color: #3a3632 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 100% !important;
  padding: 0 !important;
  width: 36px !important;
  height: 36px !important;
}

.ant-drawer-close:hover,
.ant-drawer-close:active {
  border: 2px solid #3a3632 !important;
  background-color: #3a3632 !important;
  color: #fdfdfd !important;
}
